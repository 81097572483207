import React from 'react';
import Header from '../header';
import '../products/products.css';
import { Link } from 'react-router-dom';
import ScrollToTopButton from '../ScrollButton';
import Footer from '../footer';
import { Helmet } from 'react-helmet';


function Products() {
    return (
        <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <title>Acoustic Products Manufacturer and Acoustic Products Supplier</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Our Acoustic Products" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.svg" />
                <meta property="og:locale" content="en-in" />
                <link href="https://www.acousticalsurfaces.in/products" rel="alternate" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/products" />
            </Helmet>
            <section id='products'>
                <div className='container-fluid'>
                    <Header />
                    <div className='container'>
                        <div className='col-md-12 title'>Key Products</div>
                        <div className='row para'>
                            <div className='col-md-6 text-left'>
                                <Link to={'/fabrisorb'} style={{ textDecorationLine: "none" }}><p>FabriSorb</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>FabriSorb Deco</p></Link>
                                <Link to={'/echo-eliminator'} style={{ textDecorationLine: "none" }}><p>Echo Eliminator<sup>TM</sup></p></Link>
                                <Link to={'/mass-loaded-vinyl'} style={{ textDecorationLine: "none" }}><p>MLVB</p></Link>
                                <Link to={'/woodwool'} style={{ textDecorationLine: "none" }}><p>Wood Wool</p></Link>
                                <Link to={'/decopanel'} style={{ textDecorationLine: "none" }}><p>D'ecopanel</p></Link>
                                <Link to={'/fabriwall'} style={{ textDecorationLine: "none" }}><p>FabriWALL</p></Link>
                                <Link to={'/woodtec'} style={{ textDecorationLine: "none" }}><p>WoodTEC</p></Link>
                                <Link to={'/foamstop-pu'} style={{ textDecorationLine: "none" }}><p>FOAM STOP<sup>TM</sup>Polyurethane</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Diffuso</p></Link>
                                <Link to={'/polymax-polyester'} style={{ textDecorationLine: "none" }}><p>Polymax<sup>TM</sup></p></Link>
                            </div>
                            <div className='col-md-6 text-left'>
                                <Link to={'/polysorb'} style={{ textDecorationLine: "none" }}><p>Polysorb</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Polysorb Deco</p></Link>
                                <Link to={'https://starhomecinema.com/'} style={{ textDecorationLine: "none" }}><p>Star Home Cinema</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Ecotherm</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Decowood</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Foamwood</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Polywood</p></Link>
                                <Link to={'/soundproof-interior-doors'} style={{ textDecorationLine: "none" }}><p>Acousty Door</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>ASI Knauf Danoline Kraft</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Sound Isolation Clip</p></Link>
                                <Link to={'#/'} style={{ textDecorationLine: "none" }}><p>Polymax Ceiling Tiles</p></Link>
                            </div>

                        </div>


                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    )
}

export default Products